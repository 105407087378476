import { ChartFilters } from 'src/app/modules/chart/models/chart-filters';
import { EChartIntervals, EChartPeriods, EChartTypes } from 'src/app/modules/chart/models/chart-types';
import { EPayerTypes } from 'src/app/modules/chart/models/payer-types';
import { EPositionTypes } from 'src/app/modules/chart/models/position-types';

export const defaultState: ChartFilters = {
  interval: EChartIntervals.FIVEMINUTES,
  period:EChartPeriods.SIXMONTHS,
  type: EChartTypes.LEGAL,
  position: EPositionTypes.PURE,
  payer: EPayerTypes.LEGAL,
  isOnline: true,
};
