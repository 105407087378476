import { Injectable } from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import { Observable, retry } from 'rxjs';
import { ILoginPayloadDTO, ILoginResponseDTO } from '../dto/login.dto';
import { IRegisterStartPayloadDTO, IRegisterStartResponseDTO } from '../dto/register-start.dto';
import { IRegisterContinuePayloadDTO, IRegisterContinueResponseDTO } from '../dto/register-continue.dto';
import { BASE_URL } from '../../../../constants';
import {
  IResetPasswordStartPayloadDTO,
  IResetPasswordStartResponseDTO,
} from '../dto/reset-password-start.dto';
import {
  IResetPasswordContinuePayloadDTO,
  IResetPasswordContinueResponseDTO,
} from '../dto/reset-password-continue.dto';
import {ILogoutResponseDTO} from '../dto/logout.dto';

@Injectable({
  providedIn: 'root',
})
export class AuthRepository {
  constructor(private readonly _http: HttpClient) {}

  public login(data: ILoginPayloadDTO): Observable<HttpResponse<ILoginResponseDTO>> {
    return this._http
      .post<ILoginResponseDTO>(`${BASE_URL}/api/v1/auth/login`, data, {
        observe: 'response',
        withCredentials: true,
      })
      .pipe(retry(1));
  }

  public resetPasswordStart(
    data: IResetPasswordStartPayloadDTO
  ): Observable<IResetPasswordStartResponseDTO> {
    return this._http.post<IResetPasswordStartResponseDTO>(
      `${BASE_URL}/api/v1/password/email`,
      data,
      { withCredentials: true }
    );
  }

  public resetPasswordContinue(
    data: IResetPasswordContinuePayloadDTO
  ): Observable<IResetPasswordContinueResponseDTO> {
    return this._http
      .post<IResetPasswordStartResponseDTO>(`${BASE_URL}/api/v1/password/reset`, data, {
        withCredentials: true,
      })
      .pipe(retry(1));
  }

  public registerStart(data: IRegisterStartPayloadDTO): Observable<HttpResponse<IRegisterStartResponseDTO>> {
    return this._http
      .post<IRegisterStartResponseDTO>(`${BASE_URL}/api/v1/register/email`, data, {
        observe: 'response',
        withCredentials: true,
      })
      .pipe(retry(1));
  }

  public registerContinue(data: IRegisterContinuePayloadDTO): Observable<HttpResponse<IRegisterContinueResponseDTO>> {
    return this._http
        .post<IRegisterContinueResponseDTO>(`${BASE_URL}/api/v1/register/finish`, data, {
          observe: 'response',
          withCredentials: true,
        })
        .pipe(retry(1));
  }

  public logout() {
    this._http
      .post<ILogoutResponseDTO>(`${BASE_URL}/api/v1/auth/logout`, {}, { withCredentials: true })
      .pipe(retry(1))
      .subscribe();
  }
}
