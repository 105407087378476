import { IOption } from 'src/app/shared/interfaces/chart-options.interface';

export enum EPositionTypes {
  SHORT = 'short',
  LONG = 'long',
  SHORT_LONG = 'shortlong',
  PURE = 'pure',
}

export const PositionTypesAliases: Record<EPositionTypes, string> = {
  [EPositionTypes.SHORT]: 'Продажи',
  [EPositionTypes.LONG]: 'Покупки',
  [EPositionTypes.PURE]: 'Чистая позиция',
  [EPositionTypes.SHORT_LONG]: 'Продажи Покупки',
};

export const individualPositionTypeOptions: IOption<EPositionTypes>[] = [
  {
    label: '',
    value: EPositionTypes.SHORT,
    lineLabels: [
      { text: PositionTypesAliases[EPositionTypes.SHORT], color: '#FF6CB3' },
    ],
  },
  {
    label: '',
    value: EPositionTypes.LONG,
    lineLabels: [
      { text: PositionTypesAliases[EPositionTypes.LONG], color: '#5599FF' },
    ],
  },
  {
    label: '',
    value: EPositionTypes.SHORT_LONG,
    lineLabels: [
      { text: PositionTypesAliases[EPositionTypes.SHORT], color: '#FF6CB3' },
      { text: PositionTypesAliases[EPositionTypes.LONG], color: '#5599FF' },
    ],
  },
  {
    label: '',
    value: EPositionTypes.PURE,
    lineLabels: [
      { text: PositionTypesAliases[EPositionTypes.PURE], color: '#7C5BFF' },
    ],
  },
];

export const legalPositionTypeOptions: IOption<EPositionTypes>[] = [
  {
    label: '',
    value: EPositionTypes.SHORT,
    lineLabels: [
      { text: PositionTypesAliases[EPositionTypes.SHORT], color: '#FFAD4D' },
    ],
  },
  {
    label: '',
    value: EPositionTypes.LONG,
    lineLabels: [
      { text: PositionTypesAliases[EPositionTypes.LONG], color: '#70FF00' },
    ],
  },
  {
    label: '',
    value: EPositionTypes.SHORT_LONG,
    lineLabels: [
      { text: PositionTypesAliases[EPositionTypes.SHORT], color: '#FFAD4D' },
      { text: PositionTypesAliases[EPositionTypes.LONG], color: '#70FF00' },
    ],
  },
  {
    label: '',
    value: EPositionTypes.PURE,
    lineLabels: [
      { text: PositionTypesAliases[EPositionTypes.PURE], color: '#B0BF00' },
    ],
  },
];
