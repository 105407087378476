import { Component } from '@angular/core';
import { ChartService } from '../../chart.service';
import { getLoadingOptions } from '../../chart/chart-theme';
import { AssetsService } from 'src/app/modules/assets/assets.service';
import { SubscriptionService } from '../../../subscription/subscription.service';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
})
export class ChartComponent {
  public loadingOptions: object = getLoadingOptions('#005055', 'Загрузка');

  constructor(
    public readonly chartService: ChartService,
    public readonly assetService: AssetsService,
    public readonly _subscriptionService: SubscriptionService
  ) {}

  hasData(): boolean {
    if (this.assetService.isLoading || this.chartService.isLoading) return true;

    const series = Array.isArray(this.chartService.options.series)
      ? this.chartService.options.series
      : [this.chartService.options.series];

    for (let seria of series) {
      if (seria?.data && Array.isArray(seria?.data) && seria?.data.length > 0) {
        return true;
      }
    }

    return false;
  }

  hasAsset() {
    if (this.assetService.isLoading || this.chartService.isLoading) return true;
    return !!this.chartService.currentAsset;
  }

  isChartAvailable() {
    if (this.assetService.isLoading || this.chartService.isLoading) return true;
    return this.chartService.currentAsset?.available;
  }
}
