import {
  GraphicComponentOption,
  GridComponentOption,
  TooltipComponentOption,
  XAXisComponentOption,
} from 'echarts';
import { isMobileScreen } from 'src/app/shared/utils/mobile';
import { addNullsToData } from './chart-utils';
import { EDateFilterIntervalType, IDateFilter } from '../models/date-filter';

const isSmallScreen: boolean = isMobileScreen();

export function getGridSettings(): GridComponentOption {
  return {
    left: -35,
    right: 0,
    top: 0,
    bottom: 0,
    containLabel: true,
    show: true,
    borderWidth: 0,
    backgroundColor: '#0E0E15',
  };
}

export function getTooltipSettings(dateFilter: IDateFilter): TooltipComponentOption {
  return {
    trigger: 'axis',
    className: 'small:!p-6 small:text-[16px] text-[10px] max-small:!rounded-md',
    axisPointer: {
      animation: true,
      label: {
        formatter: function (params) {
          const date = new Date(+params.value);
          if (date.getTime()) {
            if (dateFilter.type === EDateFilterIntervalType.MINUTE) {
              return date.toLocaleDateString('ru-RU', {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit'
              });
            } else {
              return date.toLocaleDateString('ru-RU', {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
              });
            }
          } else {
            return '';
          }
        },
      },
    },
    confine: true,
    backgroundColor: 'rgba(39, 40, 46, 0.60)',
    borderColor: '#1BCD54',
    position: (point: any, params: any, dom: any, rect: any, size: any) => {
      return [point[0] + 5, point[1] - size.contentSize[1] - 5];
    },
    textStyle: {
      color: '#FFF',
      fontSize: 'inherit',
    },
    transitionDuration: 0,
    extraCssText: `
          box-shadow: 8px 8px 15px 0px rgba(0, 0, 0, 0.30); 
          backdrop-filter: blur(8px); 
          border-radius: 16px 16px 16px 0; 
          z-index: 1;
        `,
  };
}

export function getAnimationSettings() {
  return {
    dataZoom: isSmallScreen
      ? []
      : [
          {
            type: 'inside',
            minSpan: 20,
            zoomOnMouseWheel: 'ctrl',
            moveOnMouseWheel: 'shift',
          },
        ],
    animation: true,
    animationDelay: 0,
    animationDuration: 300,
    animationDelayUpdate: 0,
    animationDurationUpdate: 300,
    blendMode: 'lighter',
  };
}

export function getAxisX(timestampts: number[], dateFilter: IDateFilter): XAXisComponentOption {
  const minTimestamp = Math.min(...timestampts);
  const maxTimestamp = Math.max(...timestampts);
  const periodInMilliseconds = maxTimestamp - minTimestamp;
  const oneYearInMilliseconds = 365 * 24 * 60 * 60 * 1000;
  const fourYearsInMilliseconds = 4 * oneYearInMilliseconds;
  const threeHundredDaysInMilliseconds = 300 * 24 * 60 * 60 * 1000;

  timestampts = addNullsToData<number>(timestampts);

  return {
    type: 'category',
    data: timestampts,
    axisTick: {
      alignWithLabel: true,
    },
    axisLabel: {
      hideOverlap: true,
      color: 'white',
      verticalAlign: 'top',
      align: 'center',
      formatter: function (value: string) {
        const date = new Date(+value);
        if (!date.getDate()) return '';

        if (dateFilter.type === EDateFilterIntervalType.MINUTE) {
          return date.toLocaleTimeString('ru-RU', {
            hour: '2-digit',
            minute: '2-digit',
          });
        } else if (periodInMilliseconds < threeHundredDaysInMilliseconds) {
          return date.toLocaleDateString('ru-RU', {
            day: 'numeric',
            month: isSmallScreen ? 'short' : 'long',
          });
        } else {
          return date.toLocaleDateString('ru-RU', {
            month: isSmallScreen ? 'short' : 'long',
            year: 'numeric',
          });
        }
      },
    },
    axisLine: {
      onZero: false,
      lineStyle: {
        color: '#FFF',
      },
    },
    axisPointer: {
      show: true,
      animation: false,
      snap: true,
      lineStyle: {
        color: '#577775',
        type: 'solid',
      },
    },
  };
}

export function getWatermark(): GraphicComponentOption {
  return {
    type: 'image',
    id: 'logo',
    right: isSmallScreen ? '12px' : '32px',
    bottom: isSmallScreen ? '34px' : '52px',
    bounding: 'raw',
    z: 100,
    style: {
      image: './assets/images/chart_logo--lighter.svg',
      width: isSmallScreen ? 90 : 150,
      height: isSmallScreen ? 18 : 30,
    },
  };
}

export function getLoadingOptions(
  color: string,
  text: string = 'Загрузка данных'
): object {
  return {
    color,
    text,
    textColor: '#FFF',
    fontSize: 18,
    fontFamily: 'Inter',
    fontWeight: 'bold',
    maskColor: 'rgba(0, 0, 0, 0.8)',
  };
}
