import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import {
  IconDoubleHorizontalFrameComponent,
  IconDoubleVerticalFrameComponent,
  IconFourFrameComponent,
  IconSingleFrameComponent,
  IconTripleFrameComponent,
} from 'src/app/components/icons/icons.component';
import {
  ChartView,
  chartViewOptions,
} from 'src/app/modules/chart/models/chart-types';
import { IOption } from 'src/app/shared/interfaces/chart-options.interface';

@Component({
  standalone: true,
  selector: 'app-view-select',
  templateUrl: './view-select.component.html',
  styleUrls: ['./view-select.component.scss'],
  imports: [
    CommonModule,
    IconSingleFrameComponent,
    IconDoubleHorizontalFrameComponent,
    IconDoubleVerticalFrameComponent,
    IconTripleFrameComponent,
    IconFourFrameComponent,
  ],
})
export class ViewSelectComponent {
  public isOptionsOpened: boolean = false;
  public options = chartViewOptions;
  public ChartView = ChartView;

  @Input() current: IOption<ChartView>;

  @Output() valueChanged = new EventEmitter<IOption<any>>();

  constructor(private elRef: ElementRef) {}

  setOption(option: IOption<any>) {
    if (option == this.current) return;
    this.current = option;
    this.valueChanged.emit(option);
    this.setIsOptionsOpened(false);
  }

  setIsOptionsOpened(state: boolean) {
    this.isOptionsOpened = state;
  }

  @HostListener('body:click', ['$event'])
  handleClick(event: Event) {
    if (!this.elRef.nativeElement.contains(event.target)) {
      this.setIsOptionsOpened(false);
    }
  }
}
