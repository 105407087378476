import { Component } from '@angular/core';
import { AuthService, EAuthPopUpState } from 'src/app/modules/auth/auth.service';
import { UserService } from 'src/app/modules/user/user.service';

@Component({
  selector: 'app-benefits',
  templateUrl: './benefits.component.html',
})
export class BenefitsComponent {
  constructor(public readonly authService: AuthService, public readonly userService: UserService) {}

  public handleJoinNowButton() {
    this.authService.setAuthPopUpState(EAuthPopUpState.REGISTER_START);
  }
}
