<div
  class="mb-6 flex justify-between items-center max-small:flex-col"
  [ngClass]="{ 'max-laptop:hidden': router.url.includes('/chart') }"
>
  <button
    *ngIf="!router.url.includes('/chart')"
    class="button--white small:w-[220px] laptop:ml-6 max-small:mt-5 w-full"
    routerLink="/assets"
  >
    Все активы
  </button>
</div>
<div class="relative">
  <div
    #skeleton
    [@skeletonAnimation]="chartService.isFullscreen ? 'wide' : 'small'"
    class="bg-gray3 rounded-lg top-0 right-0 bottom-0 left-0 z-[-1] absolute opacity-50 flex items-center justify-center"
  >
    <span>Полноэкранный режим</span>
  </div>

  <div
    #analytics
    class="analytics"
    [@fullscreenAnimation]="chartService.isFullscreen ? 'wide' : 'small'"
  >
    <div class="flex justify-between mb-6">
      <div
        class="flex flex-wrap justify-end max-phone2:flex-col laptop:gap-x-6 laptop:gap-4 max-laptop:gap-4"
      >
        <app-select
          class="laptop:min-w-[117px] max-laptop:min-w-[117px] max-laptop:basis-0 flex-grow max-laptop:basis-0"
          [options]="timeIntervalsOptions"
          [current]="
            getCurrentOption(
              timeIntervalsOptions,
              chartService.currentChartFiltersState.timeInterval
            )
          "
        />

        <app-select
          class="laptop:min-w-[173px] flex-grow max-laptop:basis-0"
          [options]="dateIntervalsOptions"
          [current]="
            getCurrentOption(
              dateIntervalsOptions,
              chartService.currentChartFiltersState.dateInterval
            )
          "
        />

        <app-select
          class="laptop:min-w-[237px] max-laptop:min-w-[237px] flex-grow max-laptop:basis-0"
          [options]="chartTypeOptions"
          [current]="
            getCurrentOption(
              chartTypeOptions,
              chartService.currentChartFiltersState.type
            )
          "
          (valueChanged)="setChartTypeHandler($event.value)"
        />

        <app-select
          class="laptop:min-w-[237px] max-laptop:min-w-[237px] flex-grow max-laptop:basis-0"
          *ngIf="isShowPositions()"
          [options]="positionTypeOptions"
          [current]="
            getCurrentOption(
              positionTypeOptions,
              chartService.currentChartFiltersState.position
            )
          "
          (valueChanged)="chartService.setCurrentPositionType($event.value)"
        />

        <app-select
          class="laptop:min-w-[134px] max-laptop:min-w-[134px] flex-grow max-laptop:basis-0"
          *ngIf="isShowPayer()"
          [options]="payerTypeOptions"
          [current]="
            getCurrentOption(
              payerTypeOptions,
              chartService.currentChartFiltersState.payer
            )
          "
          (valueChanged)="chartService.setCurrentPayerType($event.value)"
        />
      </div>

      <div class="min-w-[334px] flex flex-wrap max-laptop:basis-0">
        <label
          (click)="toggleFullscreen()"
          class="analytics__fullscreen min-w-[172px] max-h-[40px]"
        >
          <span class="analytics__fullscreen-label">
            {{ chartService.isFullscreen ? 'Свернуть' : 'На весь экран' }}
          </span>
          <span class="analytics__fullscreen-icon">
            <icon-fullscreen *ngIf="!chartService.isFullscreen" />
            <icon-smallscreen *ngIf="chartService.isFullscreen" />
          </span>
        </label>

        <app-view-select
          class="analytics__fullscreen min-w-[90px] max-h-[40px]"
          [current]="getCurrentOption(viewOptions, currentViewOption)"
          (valueChanged)="setChartView($event.value)"
        />

        <label
          class="px-2 ml-4 border border-radius border-greenPrimary max-h-[40px] min-w-[40px] greenPrimary__opacity"
        >
          <icon-plus />
        </label>
      </div>
    </div>

    <div class="mx-auto">
      <div
        *ngIf="currentViewOption === ChartView.SINGLE"
        class="hidden tablet:block h-[564px] bg-blue-500 w-full relative"
      >
        <ng-container *ngTemplateOutlet="first"></ng-container>
      </div>

      <div
        *ngIf="currentViewOption === ChartView.DOUBLE_HOR"
        class="hidden tablet:block tablet:space-y-4 tablet:max-h-[640px]"
      >
        <div class="h-[320px] max-h-[320px] w-full relative">
          <ng-container *ngTemplateOutlet="first"></ng-container>
        </div>
        <div class="h-[320px] max-h-[320px] w-full relative">
          <ng-container *ngTemplateOutlet="second"></ng-container>
        </div>
      </div>

      <div
        *ngIf="currentViewOption === ChartView.DOUBLE_VER"
        class="hidden tablet:flex tablet:space-x-4 tablet:h-[656px]"
      >
        <div class="h-[656px] w-1/2 relative">
          <ng-container *ngTemplateOutlet="first"></ng-container>
        </div>
        <div class="h-[656px] w-1/2 relative">
          <ng-container *ngTemplateOutlet="second"></ng-container>
        </div>
      </div>

      <div
        *ngIf="currentViewOption === ChartView.TRIPLE"
        class="hidden tablet:flex tablet:space-x-4 tablet:h-[656px]"
      >
        <div class="flex flex-col space-y-4 w-[70%]">
          <div class="h-[320px] bg-green-500 w-full relative">
            <ng-container *ngTemplateOutlet="first"></ng-container>
          </div>
          <div class="h-[320px] bg-yellow-500 w-full relative">
            <ng-container *ngTemplateOutlet="second"></ng-container>
          </div>
        </div>
        <div class="h-full bg-red-500 w-[30%] ml-4 relative">
          <ng-container *ngTemplateOutlet="news"></ng-container>
        </div>
      </div>

      <div
        *ngIf="currentViewOption === ChartView.FOUR"
        class="hidden tablet:grid tablet:grid-cols-2 tablet:grid-rows-2 tablet:gap-4 tablet:h-[640px]"
      >
        <div class="h-[320px] bg-blue-500 relative">
          <ng-container *ngTemplateOutlet="first"></ng-container>
        </div>
        <div class="h-[320px] bg-green-500 relative">
          <ng-container *ngTemplateOutlet="second"></ng-container>
        </div>
        <div class="h-[320px] bg-yellow-500 relative">
          <ng-container *ngTemplateOutlet="third"></ng-container>
        </div>
        <div class="h-[320px] bg-red-500 relative">
          <ng-container *ngTemplateOutlet="news"></ng-container>
        </div>
      </div>
    </div>

    <div class="block tablet:hidden flex flex-col space-y-4">
      <div class="h-[320px] w-full relative">
        <ng-container *ngTemplateOutlet="first"></ng-container>
      </div>
      <div
        *ngIf="currentViewOption !== ChartView.SINGLE"
        class="h-[320px] w-full relative"
      >
        <ng-container *ngTemplateOutlet="second"></ng-container>
      </div>
      <div
        *ngIf="currentViewOption === ChartView.FOUR"
        class="h-[320px] w-full relative"
      >
        <ng-container *ngTemplateOutlet="third"></ng-container>
      </div>
      <div
        *ngIf="
          currentViewOption === ChartView.TRIPLE ||
          currentViewOption === ChartView.FOUR
        "
        class="h-[320px] w-full relative"
      >
        <ng-container *ngTemplateOutlet="news"></ng-container>
      </div>
    </div>

    <p
      class="analytics__description"
      *ngIf="
        chartService.currentChartFiltersState.type == EChartTypes.INDIVIDUAL ||
        chartService.currentChartFiltersState.type == EChartTypes.LEGAL
      "
    >
      <b>График шорт позиций</b> – отображает объём шорт (продажных) позиций на
      срочном рынке.
      <br />
      <b>График лонг позиций</b> – показывает объём лонг (покупных) позиций на
      срочном рынке.
      <br />
      <b>График чистых позиций</b> – вычисляет разницу между лонг и шорт
      позициями.
    </p>

    <p
      class="analytics__description"
      *ngIf="chartService.currentChartFiltersState.type == EChartTypes.RSI"
    >
      Показывает уровень перекупленности или перепроданности участников рынка за
      выбранный временной диапазон. Сигналы генерируются на основе чистых
      позиций количества договоров, когда RSI пересекает верхнюю или нижнюю
      границу, установленную на основе стандартного отклонения от среднего
      значения RSI за этот период.
    </p>

    <p
      class="analytics__description"
      *ngIf="chartService.currentChartFiltersState.type == EChartTypes.FUTURES"
    >
      Итоги торгов по фьючерсному контракту с ближайший датой исполнения за
      период. Демонстрирует текущую стоимость фьючерса, изменяя цвет на красный
      или зелёный в зависимости от пересечения границ RSI в открытом интересе
      участников срочного рынка.
    </p>
  </div>
</div>

<ng-template #first>
  <app-chart />

  <app-asset-picker
    class="small:max-w-[80%] absolute top-0 mt-4 ml-20"
    [avalibleAssets]="assetsService.avalibleAssets"
    [unavalibleAssets]="assetsService.unavalibleAssets"
  />
</ng-template>

<ng-template #second>
  <app-chart />

  <app-asset-picker
    class="small:max-w-[80%] absolute top-0 mt-4 ml-20"
    [avalibleAssets]="assetsService.avalibleAssets"
    [unavalibleAssets]="assetsService.unavalibleAssets"
  />
</ng-template>

<ng-template #third>
  <app-chart />

  <app-asset-picker
    class="small:max-w-[80%] absolute top-0 mt-4 ml-20"
    [avalibleAssets]="assetsService.avalibleAssets"
    [unavalibleAssets]="assetsService.unavalibleAssets"
  />
</ng-template>

<ng-template #news>
  <div>тут новости</div>
</ng-template>
