import {
  getAnimationSettings,
  getAxisX,
  getGridSettings,
  getTooltipSettings,
  getWatermark,
} from '../chart-theme';
import { IChartData } from '../../models/chart';
import { EChartsOption } from 'echarts';
import { EDynamicColors } from '../chart-colors';
import { addNullsToData, generateVisualMapPieces } from '../chart-utils';
import { IDateFilter } from '../../models/date-filter';

export function getPriceChartOptions(
  chartData: IChartData,
  overbought: number,
  oversold: number,
  rsiData: number[],
  dateFilter: IDateFilter,
): EChartsOption {
  let min = Math.floor(Math.min(...chartData.data) * (1 - 1 / 100));
  let max = Math.ceil(Math.max(...chartData.data) * (1 + 1 / 100));

  const data = addNullsToData<number>(chartData.data);
  rsiData = addNullsToData<number>(rsiData);

  return {
    ...getAnimationSettings(),
    graphic: getWatermark(),
    textStyle: {
      fontSize: 14,
      fontFamily: 'Inter',
    },
    grid: getGridSettings(),
    title: {
      show: false,
    },
    tooltip: getTooltipSettings(dateFilter),
    xAxis: getAxisX(chartData.timestamps, dateFilter),
    yAxis: {
      type: 'value',
      boundaryGap: [0, '10%'],
      min: min,
      max: max,
      splitLine: {
        show: true,
        lineStyle: {
          color: '#CFCFD0',
          opacity: 0.25,
        },
      },
      axisLabel: {
        margin: 35,
        inside: true,
        color: 'white',
        verticalAlign: 'bottom',
        align: 'left',
      },
    },
    series: [
      {
        name: chartData.name,
        type: 'line',
        showSymbol: false,
        symbolSize: 8,
        emphasis: {
          itemStyle: {
            borderColor: EDynamicColors.NEUTRAL,
            borderWidth: 13,
            opacity: 0.3,
            color: EDynamicColors.NEUTRAL,
          },
        },
        data: data,
      },
    ],
    // @ts-ignore
    visualMap: {
      show: false,
      dimension: 0,
      pieces: generateVisualMapPieces(overbought, oversold, rsiData),
      seriesIndex: 0,
    },
  };
}
