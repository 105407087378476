import { Injectable } from '@angular/core';
import { IAsset } from '../assets/models/assets';
import {
  ChartView,
  EChartDateIntervals,
  EChartTimeIntervals,
  EChartTypes,
} from './models/chart-types';
import { EPayerTypes, PayerTypessAliases } from './models/payer-types';
import { dateFilters, IDateFilter } from './models/date-filter';
import { AssetsRepository } from '../assets/repository/assets';
import { NotifierService } from 'angular-notifier';
import { AssetsService } from '../assets/assets.service';
import { ChartRepository } from './repository/chart';
import { getTimestamp } from 'src/app/shared/utils/stringed-date';
import { EChartsOption } from 'echarts';
import { EPositionTypes, PositionTypesAliases } from './models/position-types';
import { IChartData } from './models/chart';
import { getSoloChartOptions } from './chart/options/solo-chart-options';
import { IPositionReport, IPriceReport, IRsiReport } from './models/reports';
import {
  individualPositionColors,
  legalPositionColors,
} from './chart/chart-colors';
import { getDuoChartOptions } from './chart/options/duo-chart-options';
import { getRsiChartOptions } from './chart/options/rsi-chart-options';
import { getPriceChartOptions } from './chart/options/price-chart-options';
import { Observable } from 'rxjs';
import { ChartFilters } from './models/chart-filters';

@Injectable({
  providedIn: 'root',
})
export class ChartService {
  public isLoading: boolean = false;
  public isFullscreen: boolean = false;

  public currentAsset: IAsset | null = null;

  public dateFilter: IDateFilter = dateFilters[2];

  public options: EChartsOption = {};

  private readonly _currentChartFiltersState: ChartFilters;

  public get isChartPage(): boolean {
    return location.pathname == '/chart';
  }

  public get currentChartFiltersState(): ChartFilters {
    return this._currentChartFiltersState;
  }

  public get payerAlias(): string {
    return PayerTypessAliases[this.currentChartFiltersState.payer];
  }

  constructor(
    private readonly _assetsRepository: AssetsRepository,
    private readonly _chartRepository: ChartRepository,
    private readonly _notifierService: NotifierService,
    private readonly _assetServise: AssetsService
  ) {
    this._currentChartFiltersState = {
      timeInterval: EChartTimeIntervals.FIVEMINUTES,
      dateInterval: EChartDateIntervals.TODAY,
      type: EChartTypes.LEGAL,
      position: EPositionTypes.SHORT_LONG,
      payer: EPayerTypes.LEGAL,
      view: ChartView.SINGLE,
    };
  }

  public setCurrentAsset(asset: IAsset | null): void {
    if (asset && this.currentAsset?.id == asset.id) return;
    this.currentAsset = asset;
    this.fetchReports();
  }

  public setCurrentChartType(type: EChartTypes): void {
    if (this.currentChartFiltersState.type === type) return;
    this.patchChartFiltersState('type', type);
    this.fetchReports();
  }

  public setDateFilter(filter: IDateFilter): void {
    this.dateFilter = filter;
    this.fetchReports();
  }

  public setCurrentPayerType(payer: EPayerTypes): void {
    this.patchChartFiltersState('payer', payer);
    this.fetchReports();
  }

  public setCurrentPositionType(position: EPositionTypes): void {
    this.patchChartFiltersState('position', position);
    this.updateChart();
  }

  public fetchAssetByIsin(isin: string) {
    this.isLoading = true;
    this._assetsRepository.getAssets('', null, isin).subscribe((response) => {
      if (response.success) {
        const asset = response.data.avalible[0] || response.data.unavalible[0];
        if (asset) {
          this.setCurrentAsset(asset);
          this._assetServise.setType(asset.type);
        } else {
          this._notifierService.notify('error', 'Актив не найден');
        }
      } else if (response.error) {
        this._notifierService.notify(
          'error',
          'Не удалось загрузить данные актива'
        );
      }
      this.isLoading = false;
    });
  }

  // FIXME рефакторинг все что ниже!!!
  // FIXME рефакторинг все что ниже!!!
  // FIXME рефакторинг все что ниже!!!
  public reports: (IPositionReport | IPriceReport | IRsiReport)[];

  public updateChart(): void {
    switch (this.currentChartFiltersState.type) {
      case EChartTypes.INDIVIDUAL:
        this.updateIndividualPositionsOptions();
        break;
      case EChartTypes.LEGAL:
        this.updateLegalPositionsOptions();
        break;
      case EChartTypes.RSI:
        this.patchChartFiltersState('position', EPositionTypes.SHORT_LONG);
        this.updateRsiOptions();
        break;
      case EChartTypes.FUTURES:
        // this.currentPayerType = EPayerTypes.LEGAL;
        this.patchChartFiltersState('position', EPositionTypes.SHORT_LONG);
        this.updatePriceOptions();
        break;
    }
  }

  private updateIndividualPositionsOptions(): void {
    const reports = this.reports as IPositionReport[];

    const timestamps: number[] = [];
    const shortData: number[] = [];
    const longData: number[] = [];
    const pureData: number[] = [];

    for (let report of reports) {
      const timestamp: number = getTimestamp(report.moment);
      const { short, long, pure } = report.value;

      timestamps.push(timestamp);
      shortData.push(short);
      longData.push(long);
      pureData.push(pure);
    }

    const shortChartData: IChartData<number> = {
      name: `${this.payerAlias} (${
        PositionTypesAliases[EPositionTypes.SHORT]
      })`,
      color: individualPositionColors[EPositionTypes.SHORT],
      timestamps: timestamps,
      data: shortData,
    };

    const longChartData: IChartData<number> = {
      name: `${this.payerAlias} (${PositionTypesAliases[EPositionTypes.LONG]})`,
      color: individualPositionColors[EPositionTypes.LONG],
      timestamps: timestamps,
      data: longData,
    };

    const pureChartData: IChartData<number> = {
      name: `${this.payerAlias} (${PositionTypesAliases[EPositionTypes.PURE]})`,
      color: individualPositionColors[EPositionTypes.PURE],
      timestamps: timestamps,
      data: pureData,
    };

    switch (this.currentChartFiltersState.position) {
      case EPositionTypes.SHORT:
        this.options = getSoloChartOptions(shortChartData, this.dateFilter);
        break;
      case EPositionTypes.LONG:
        this.options = getSoloChartOptions(longChartData, this.dateFilter);
        break;
      case EPositionTypes.PURE:
        this.options = getSoloChartOptions(pureChartData, this.dateFilter);
        break;
      case EPositionTypes.SHORT_LONG:
        this.options = getDuoChartOptions(
          [shortChartData, longChartData],
          this.dateFilter
        );
        break;
    }
  }

  private updateLegalPositionsOptions(): void {
    const reports = this.reports as IPositionReport[];

    const timestamps: number[] = [];
    const shortData: number[] = [];
    const longData: number[] = [];
    const pureData: number[] = [];

    for (let report of reports) {
      const timestamp: number = getTimestamp(report.moment);
      const { short, long, pure } = report.value;

      timestamps.push(timestamp);
      shortData.push(short);
      longData.push(long);
      pureData.push(pure);
    }

    const shortChartData: IChartData<number> = {
      name: `${this.payerAlias} (${
        PositionTypesAliases[EPositionTypes.SHORT]
      })`,
      color: legalPositionColors[EPositionTypes.SHORT],
      timestamps: timestamps,
      data: shortData,
    };

    const longChartData: IChartData<number> = {
      name: `${this.payerAlias} (${PositionTypesAliases[EPositionTypes.LONG]})`,
      color: legalPositionColors[EPositionTypes.LONG],
      timestamps: timestamps,
      data: longData,
    };

    const pureChartData: IChartData<number> = {
      name: `${this.payerAlias} (${PositionTypesAliases[EPositionTypes.PURE]})`,
      color: legalPositionColors[EPositionTypes.PURE],
      timestamps: timestamps,
      data: pureData,
    };

    switch (this.currentChartFiltersState.position) {
      case EPositionTypes.SHORT:
        this.options = getSoloChartOptions(shortChartData, this.dateFilter);
        break;
      case EPositionTypes.LONG:
        this.options = getSoloChartOptions(longChartData, this.dateFilter);
        break;
      case EPositionTypes.PURE:
        this.options = getSoloChartOptions(pureChartData, this.dateFilter);
        break;
      case EPositionTypes.SHORT_LONG:
        this.options = getDuoChartOptions(
          [shortChartData, longChartData],
          this.dateFilter
        );
        break;
    }
  }

  private updateRsiOptions(): void {
    const reports = this.reports as IRsiReport[];
    const timestamps: number[] = [];
    const data: number[] = [];

    for (let report of reports) {
      const timestamp: number = getTimestamp(report.moment);
      const value: number = report.value;
      timestamps.push(timestamp);
      data.push(value);
    }

    const payerAlias: string =
      PayerTypessAliases[this.currentChartFiltersState.payer];
    const chartData: IChartData<number> = {
      name: `Сигнал (${payerAlias})`,
      timestamps: timestamps,
      data: data,
    };

    let payerField: 'yur' | 'fiz';
    switch (this.currentChartFiltersState.payer) {
      case EPayerTypes.INDIVIDUAL:
        payerField = 'fiz';
        break;
      case EPayerTypes.LEGAL:
        payerField = 'yur';
        break;
    }

    const overbought = this.currentAsset?.rsi?.[payerField]?.overbought || 70;
    const oversold = this.currentAsset?.rsi?.[payerField]?.oversold || 30;

    this.options = getRsiChartOptions(
      overbought,
      oversold,
      chartData,
      this.dateFilter
    );
  }

  private updatePriceOptions(): void {
    const reports = this.reports as IPriceReport[];

    const timestamps: number[] = [];
    const data: number[] = [];
    const rsiData: number[] = [];

    for (let report of reports) {
      const timestamp: number = getTimestamp(report.moment);
      const value: number = report.value;
      const rsi: number = report.rsi;

      timestamps.push(timestamp);
      data.push(value);
      rsiData.push(rsi);
    }

    const chartData: IChartData = {
      name: 'Фьючерс',
      timestamps: timestamps,
      data: data,
    };

    let payerField: 'yur' | 'fiz';
    switch (this.currentChartFiltersState.payer) {
      case EPayerTypes.INDIVIDUAL:
        payerField = 'fiz';
        break;
      case EPayerTypes.LEGAL:
        payerField = 'yur';
        break;
    }

    const overbought = this.currentAsset?.rsi?.[payerField]?.overbought || 70;
    const oversold = this.currentAsset?.rsi?.[payerField]?.oversold || 30;

    this.options = getPriceChartOptions(
      chartData,
      overbought,
      oversold,
      rsiData,
      this.dateFilter
    );
  }

  public patchChartFiltersState<ChartFiltersKey extends keyof ChartFilters>(
    key: ChartFiltersKey,
    value: ChartFilters[ChartFiltersKey]
  ): void {
    this._currentChartFiltersState[key] = value;
  }

  private fetchReports(): void {
    if (!this.currentAsset?.available) return;

    this.isLoading = true;
    this.reports = [];

    const izFiz: boolean =
      this.currentChartFiltersState.payer == EPayerTypes.INDIVIDUAL;

    let fetchPosition: Observable<any>;

    switch (this.currentChartFiltersState.type) {
      case EChartTypes.INDIVIDUAL:
        fetchPosition = this._chartRepository.getIndividualPosition(
          this.currentAsset.id,
          this.dateFilter,
          izFiz
        );
        break;
      case EChartTypes.LEGAL:
        fetchPosition = this._chartRepository.getLegalPosition(
          this.currentAsset.id,
          this.dateFilter,
          izFiz
        );
        break;
      case EChartTypes.RSI:
        fetchPosition = this._chartRepository.getRsi(
          this.currentAsset.id,
          this.dateFilter,
          izFiz
        );
        break;
      case EChartTypes.FUTURES:
        fetchPosition = this._chartRepository.getPrice(
          this.currentAsset.id,
          this.dateFilter,
          izFiz
        );
        break;
    }

    fetchPosition.subscribe((response) => {
      this.reports = response.data;
      this.isLoading = false;
      this.updateChart();
    });
  }
}
