// prettier-ignore
import { Component, ElementRef, HostListener, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { AssetsService } from 'src/app/modules/assets/assets.service';
import {
  EAssetTypes,
  assetTypesOptions,
} from 'src/app/modules/assets/models/asset-types';
import { IAsset } from 'src/app/modules/assets/models/assets';
import { ChartService } from '../../chart.service';
import { Router } from '@angular/router';
import { SearchBarComponent } from 'src/app/components/ui/controls/search-bar/search-bar.component';
import { IOption } from 'src/app/shared/interfaces/chart-options.interface';

@Component({
  selector: 'app-asset-picker',
  templateUrl: './asset-picker.component.html',
  styleUrls: ['./asset-picker.component.scss'],
})
export class AssetPickerComponent implements OnInit, OnChanges {
  @Input() avalibleAssets: IAsset[];
  @Input() unavalibleAssets: IAsset[];

  @ViewChild('assetsList') assetsList: ElementRef;
  @ViewChild(SearchBarComponent, { static: false })
  searchBarComponent: SearchBarComponent;

  public isOpened: boolean = false;
  public assetTypesOptions: IOption<EAssetTypes>[] = assetTypesOptions;
  public possibleAvailableAssets: IAsset[] = [];

  constructor(
    private elRef: ElementRef,
    public readonly router: Router,
    public readonly chartService: ChartService,
    public readonly assetsService: AssetsService
  ) {}

  ngOnInit(): void {
    this.possibleAvailableAssets = this.avalibleAssets.slice();
  }

  changeTypeHandler(type: EAssetTypes): void {
    if (!this.isPickerEnabled() || this.assetsService.type == type) return;
    this.assetsService.setType(type);
    this.searchBarComponent.clearValue.next();
  }

  changeAssetHandler(asset: IAsset): void {
    if (!this.isPickerEnabled()) return;
    this.isOpened = false;
    if (location.pathname == '/chart') {
      this.router.navigate([], {
        queryParams: {
          isin: asset.isin,
        },
      });
    }
    this.chartService.setCurrentAsset(asset);
    this.assetsService.type = asset.type;
  }

  toggleOpened(): void {
    if (!this.isPickerEnabled()) return;
    this.isOpened = !this.isOpened;
  }

  isPickerEnabled(): boolean {
    return !(this.assetsService.isLoading || this.chartService.isLoading);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['avalibleAssets']) {
      setTimeout(() => {
        this.assetsList.nativeElement.scrollTop = 0;
      }, 0);

      const currentAsset = this.chartService.currentAsset;
      const firstAvalibleAsset = this.avalibleAssets[0];

      if (
        (!currentAsset && !this.chartService.isLoading) ||
        currentAsset?.type != this.assetsService.type
      ) {
        this.chartService.setCurrentAsset(firstAvalibleAsset);

        if (location.pathname == '/chart') {
          this.router.navigate([], {
            queryParams: {
              isin: firstAvalibleAsset.isin,
            },
          });
        }
      }
    }
  }

  @HostListener('body:click', ['$event'])
  handleClick(event: Event) {
    if (!this.elRef.nativeElement.contains(event.target)) {
      this.isOpened = false;
    }
  }

  setSearchValue(value: IAsset | null): void {
    if (value) {
      this.changeAssetHandler(value);
    }
  }

  setSearchLine(line: string): void {
    line = line.trim();
    this.possibleAvailableAssets = this.getPossibleAvailableAssets(line);
  }

  private getPossibleAvailableAssets(filterValue: string): IAsset[] {
    const filterAvailableAssets = (value: string) => {
      const rootAssets: IAsset[] = this.avalibleAssets.slice();
      const filteredAssets = rootAssets.filter((asset: IAsset): boolean => {
        const searchString = value.toLowerCase();

        if (!asset.name.toLowerCase().includes(searchString)) {
          return false;
        }

        return true;
      });

      return filteredAssets;
    };

    return filterValue ? filterAvailableAssets(filterValue) : [];
  }
}
