import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { trigger } from '@angular/animations';
import { fullscreenAnimation, skeletonAnimation } from './fullscreen-animation';
import { Router } from '@angular/router';
import { AssetsService } from 'src/app/modules/assets/assets.service';
import {
  ChartView,
  EChartTypes,
  chartViewOptions,
} from '../../models/chart-types';
import { ChartService } from '../../chart.service';
import { IOption } from 'src/app/shared/interfaces/chart-options.interface';
import { getCurrentOption } from 'src/app/shared/utils/options';
import { ChartViewService, ComponentObject } from '../../chart-view.service';
import { EActiveTypes } from '../../models/actives-filters';
import { ChartComponent } from '../chart/chart.component';
import { NewsComponent } from 'src/app/components/features/news/news.component';
import { defaultState } from 'src/app/components/chart-filters/default-state';
import { FiltersService } from 'src/app/components/chart-filters/filters.service';
import { findLastIndex } from 'lodash';

@Component({
  selector: 'app-chart-panel',
  templateUrl: './chart-panel.component.html',
  styleUrls: ['./chart-panel.component.scss'],
  animations: [
    trigger('fullscreenAnimation', fullscreenAnimation),
    trigger('skeletonAnimation', skeletonAnimation),
  ],
})
export class ChartPanelComponent implements AfterViewInit, OnDestroy {
  @ViewChild('analytics') chartPanel: ElementRef;
  @ViewChild('skeleton') skeletonPanel: ElementRef;

  @ViewChild('first', { read: ViewContainerRef }) first: ViewContainerRef;
  @ViewChild('second', { read: ViewContainerRef }) second: ViewContainerRef;
  @ViewChild('third', { read: ViewContainerRef }) third: ViewContainerRef;
  @ViewChild('fourth', { read: ViewContainerRef }) fourth: ViewContainerRef;

  public viewOptions: IOption<ChartView>[] = chartViewOptions;

  public ChartView = ChartView;
  public getCurrentOption = getCurrentOption;

  public get chartView(): ChartView {
    return this.chartViewService.view;
  }

  public get componentsArray(): (ComponentObject | undefined)[] {
    return this.chartViewService.componentsArray;
  }

  constructor(
    public readonly router: Router,
    public readonly assetsService: AssetsService,
    public readonly chartService: ChartService,
    public readonly chartViewService: ChartViewService,
    private readonly filtersService: FiltersService
  ) {}

  ngAfterViewInit(): void {
    this.chartViewService.setContainers(
      this.first,
      this.second,
      this.third,
      this.fourth
    );
    this.chartViewService.drawComponent(false, true);

    const newState = { ...defaultState };
    this.filtersService.notifyActiveComponent(newState);
    this.filtersService.updateState$.next(newState);
  }

  ngOnDestroy(): void {
    this.chartService.currentAsset = null;
    this.chartService.options = {};
  }

  makeComponentActive(index: number): void {
    const isNews =
      this.componentsArray[index]?.component.instance instanceof NewsComponent;
    if (isNews) return;

    this.componentsArray.forEach((c) => {
      if (c?.component.instance && 'isActive' in c.component.instance) {
        c.component.instance.isActive = false;
      }
    });

    (
      this.componentsArray[index]?.component.instance as ChartComponent
    ).isActive = true;
    (
      this.componentsArray[index]?.component.instance as ChartComponent
    ).setIsActive();
  }

  isActive(index: number): boolean {
    const instance = this.componentsArray[index]?.component.instance ?? null;
    if (instance instanceof NewsComponent) return false;

    return (
      instance instanceof ChartComponent &&
      instance.isActive &&
      instance.canShowBorder
    );
  }

  setChartView(view: ChartView, drawNews = false): void {
    this.chartViewService.view = view;
    this.chartViewService.drawComponent(drawNews);
    this.makeComponentActive(this.getLastChartIndex());
  }

  toggleFullscreen(): void {
    setTimeout(() => {
      if (this.chartService.isFullscreen) {
        document.body.style.overflow = '';
        this.chartService.isFullscreen = false;
      } else {
        this.skeletonPanel.nativeElement.style.height = `${this.chartPanel.nativeElement.offsetHeight}px`;
        this.skeletonPanel.nativeElement.style.position = 'static';
        document.body.style.overflow = 'hidden';
        this.chartService.isFullscreen = true;
      }
    }, 0);
  }

  addChart(chartType: EActiveTypes): void {
    // проверяем максимум компонентов на экране
    if (this.componentsArray.filter((c) => c).length === 4) {
      return;
    }

    const newView = this.resolveViewAfterAdd();

    switch (chartType) {
      case EActiveTypes.SIGNALS:
        const newState = {
          ...defaultState,
          type: EChartTypes.RSI,
        };
        this.setChartView(newView);
        setTimeout(() => {
          this.filtersService.notifyActiveComponent(newState);
          this.filtersService.updateState$.next(newState);
          this.makeComponentActive(this.getLastChartIndex());
        }, 100);
        break;
      case EActiveTypes.NEWS:
        this.setChartView(newView, true);
        break;
      default:
        this.setChartView(newView);
        this.makeComponentActive(this.getLastChartIndex());
        break;
    }
  }

  closeChart(index: number): void {
    const newView = this.resolveViewAfterRemove(index);
    this.chartViewService.indexToRemove = index;
    this.setChartView(newView);
    this.chartViewService.indexToRemove = null;
  }

  private resolveViewAfterRemove(index: number): ChartView {
    switch (this.chartView) {
      case ChartView.DOUBLE_HOR:
      case ChartView.DOUBLE_VER:
        return ChartView.SINGLE;
      case ChartView.TRIPLE:
        return index === 0 || index === 1
          ? ChartView.DOUBLE_VER
          : index === 2
          ? ChartView.DOUBLE_HOR
          : ChartView.DOUBLE_HOR;
      case ChartView.FOUR:
        return ChartView.TRIPLE;
      default:
        return ChartView.SINGLE;
    }
  }

  private resolveViewAfterAdd(): ChartView {
    switch (this.chartView) {
      case ChartView.DOUBLE_HOR:
      case ChartView.DOUBLE_VER:
        return ChartView.TRIPLE;
      case ChartView.TRIPLE:
        return ChartView.FOUR;
      case ChartView.FOUR:
        return ChartView.FOUR;
      case ChartView.SINGLE:
        return ChartView.DOUBLE_VER;
    }
  }

  private getLastChartIndex(): number {
    return findLastIndex(
      this.componentsArray,
      (c) => Boolean(c) && 'isActive' in c!.component.instance
    );
  }
}
