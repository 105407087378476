import { Injectable } from '@angular/core';
import { IAsset } from '../assets/models/assets';
import { EChartTypes } from './models/chart-types';
import { EPayerTypes, PayerTypessAliases } from './models/payer-types';
import { EDateFilterIntervalType, IDateFilter } from './models/date-filter';
import { AssetsRepository } from '../assets/repository/assets';
import { NotifierService } from 'angular-notifier';
import { AssetsService } from '../assets/assets.service';
import { ChartRepository } from './repository/chart';
import { getTimestamp } from 'src/app/shared/utils/stringed-date';
import { EChartsOption } from 'echarts';
import { EPositionTypes, PositionTypesAliases } from './models/position-types';
import { IChartData } from './models/chart';
import { getSoloChartOptions } from './chart/options/solo-chart-options';
import { IPositionReport, IPriceReport, IRsiReport } from './models/reports';
import {
  individualPositionColors,
  legalPositionColors,
} from './chart/chart-colors';
import { getDuoChartOptions } from './chart/options/duo-chart-options';
import { getRsiChartOptions } from './chart/options/rsi-chart-options';
import { getPriceChartOptions } from './chart/options/price-chart-options';
import { Observable } from 'rxjs';
import { ChartFilters } from './models/chart-filters';
import {
  convertIntervalAndPeriodToDateFilter,
  convertIntervalToDimension,
} from './tools/date-time.helper';
import { defaultState } from 'src/app/components/chart-filters/default-state';
import { FiltersService } from '../../components/chart-filters/filters.service';
import { cloneDeep } from 'lodash';

@Injectable()
export class ChartService {
  public isLoading: boolean = false;
  public isFullscreen: boolean = false;

  public currentAsset: IAsset | null = null;

  public options: EChartsOption = {};

  private timer: NodeJS.Timer;
  private dateFilter: IDateFilter;

  constructor(
    private readonly _assetsRepository: AssetsRepository,
    private readonly _chartRepository: ChartRepository,
    private readonly _notifierService: NotifierService,
    private readonly filtersService: FiltersService,
    private readonly _assetServise: AssetsService
  ) {
    this.updateTimer(defaultState);
    this.updateDateFilter(defaultState);
    this.fetchReports(defaultState);
  }

  public setCurrentAsset(
    asset: IAsset | null,
    filtersState: ChartFilters
  ): void {
    if (asset && this.currentAsset?.id == asset.id) return;
    this.currentAsset = asset;
    let filterStateChange = false;

    if (asset && filtersState.isOnline !== asset.isOnline) {
      const newFilterState = cloneDeep(filtersState);
      newFilterState.isOnline = asset.isOnline;

      if (this.filtersService.updateIntervalValue(newFilterState)) {
        filterStateChange = true;
      }

      if (this.filtersService.updatePeriodValue(newFilterState)) {
        filterStateChange = true;
      }

      this.filtersService.updateState$.next(newFilterState);
      this.filtersService.notifyActiveComponent(newFilterState);
    }

    if (!filterStateChange) {
      this.fetchReports(filtersState);
    }
  }

  public fetchAssetByIsin(isin: string, filtersState: ChartFilters) {
    this.isLoading = true;
    this._assetsRepository.getAssets('', null, isin).subscribe((response) => {
      if (response.success) {
        const asset = response.data.avalible[0] || response.data.unavalible[0];
        if (asset) {
          this.setCurrentAsset(asset, filtersState);
          this._assetServise.setType(asset.type);
        } else {
          this._notifierService.notify('error', 'Актив не найден');
        }
      } else if (response.error) {
        this._notifierService.notify(
          'error',
          'Не удалось загрузить данные актива'
        );
      }
      this.isLoading = false;
    });
  }

  // FIXME рефакторинг все что ниже!!!
  // FIXME рефакторинг все что ниже!!!
  // FIXME рефакторинг все что ниже!!!
  public reports: (IPositionReport | IPriceReport | IRsiReport)[];

  public updateChart(state?: ChartFilters): void {
    const payerAlias = state
      ? PayerTypessAliases[state.payer]
      : EChartTypes.INDIVIDUAL;

    switch (state?.type) {
      case EChartTypes.INDIVIDUAL:
        this.updateIndividualPositionsOptions(payerAlias, state.position);
        break;
      case EChartTypes.LEGAL:
        this.updateLegalPositionsOptions(payerAlias, state.position);
        break;
      case EChartTypes.RSI:
        this.updateRsiOptions(payerAlias, state.payer);
        break;
      case EChartTypes.FUTURES:
        //this.currentPayerType = EPayerTypes.LEGAL;
        this.updatePriceOptions(state.payer);
        break;
      default:
        this.updateIndividualPositionsOptions(payerAlias, state?.position);
        break;
    }
  }

  private updateIndividualPositionsOptions(
    payerAlias: string,
    position?: EPositionTypes
  ): void {
    const reports = this.reports as IPositionReport[];

    const timestamps: number[] = [];
    const shortData: number[] = [];
    const longData: number[] = [];
    const pureData: number[] = [];

    for (let report of reports) {
      const timestamp: number = getTimestamp(report.moment);
      const { short, long, pure } = report.value;

      timestamps.push(timestamp);
      shortData.push(short);
      longData.push(long);
      pureData.push(pure);
    }

    const shortChartData: IChartData<number> = {
      name: `${payerAlias} (${PositionTypesAliases[EPositionTypes.SELLER]})`,
      color: individualPositionColors[EPositionTypes.SHORT],
      timestamps: timestamps,
      data: shortData,
    };

    const longChartData: IChartData<number> = {
      name: `${payerAlias} (${PositionTypesAliases[EPositionTypes.BUYER]})`,
      color: individualPositionColors[EPositionTypes.LONG],
      timestamps: timestamps,
      data: longData,
    };

    const pureChartData: IChartData<number> = {
      name: `${payerAlias} (${PositionTypesAliases[EPositionTypes.PURE]})`,
      color: individualPositionColors[EPositionTypes.PURE],
      timestamps: timestamps,
      data: pureData,
    };

    switch (position) {
      case EPositionTypes.SHORT:
        this.options = getSoloChartOptions(shortChartData, this.dateFilter);
        break;
      case EPositionTypes.LONG:
        this.options = getSoloChartOptions(longChartData, this.dateFilter);
        break;
      case EPositionTypes.PURE:
        this.options = getSoloChartOptions(pureChartData, this.dateFilter);
        break;
      case EPositionTypes.SHORT_LONG:
        this.options = getDuoChartOptions(
          [shortChartData, longChartData],
          this.dateFilter
        );
        break;
    }
  }

  private updateLegalPositionsOptions(
    payerAlias: string,
    position: EPositionTypes
  ): void {
    const reports = this.reports as IPositionReport[];

    const timestamps: number[] = [];
    const shortData: number[] = [];
    const longData: number[] = [];
    const pureData: number[] = [];

    for (let report of reports) {
      const timestamp: number = getTimestamp(report.moment);
      const { short, long, pure } = report.value;

      timestamps.push(timestamp);
      shortData.push(short);
      longData.push(long);
      pureData.push(pure);
    }

    const shortChartData: IChartData<number> = {
      name: `${payerAlias} (${PositionTypesAliases[EPositionTypes.SHORT]})`,
      color: legalPositionColors[EPositionTypes.SHORT],
      timestamps: timestamps,
      data: shortData,
    };

    const longChartData: IChartData<number> = {
      name: `${payerAlias} (${PositionTypesAliases[EPositionTypes.LONG]})`,
      color: legalPositionColors[EPositionTypes.LONG],
      timestamps: timestamps,
      data: longData,
    };

    const pureChartData: IChartData<number> = {
      name: `${payerAlias} (${PositionTypesAliases[EPositionTypes.PURE]})`,
      color: legalPositionColors[EPositionTypes.PURE],
      timestamps: timestamps,
      data: pureData,
    };

    switch (position) {
      case EPositionTypes.SHORT:
        this.options = getSoloChartOptions(shortChartData, this.dateFilter);
        break;
      case EPositionTypes.LONG:
        this.options = getSoloChartOptions(longChartData, this.dateFilter);
        break;
      case EPositionTypes.PURE:
        this.options = getSoloChartOptions(pureChartData, this.dateFilter);
        break;
      case EPositionTypes.SHORT_LONG:
        this.options = getDuoChartOptions(
          [shortChartData, longChartData],
          this.dateFilter
        );
        break;
    }
  }

  private updateRsiOptions(payerAlias: string, payer: EPayerTypes): void {
    const reports = this.reports as IRsiReport[];
    const timestamps: number[] = [];
    const data: number[] = [];

    for (let report of reports) {
      const timestamp: number = getTimestamp(report.moment);
      const value: number = report.value;
      timestamps.push(timestamp);
      data.push(value);
    }

    const chartData: IChartData<number> = {
      name: `Сигнал (${payerAlias})`,
      timestamps: timestamps,
      data: data,
    };

    let payerField: 'yur' | 'fiz';
    switch (payer) {
      case EPayerTypes.INDIVIDUAL:
        payerField = 'fiz';
        break;
      case EPayerTypes.LEGAL:
        payerField = 'yur';
        break;
    }

    const overbought = this.currentAsset?.rsi?.[payerField]?.overbought || 70;
    const oversold = this.currentAsset?.rsi?.[payerField]?.oversold || 30;

    this.options = getRsiChartOptions(
      overbought,
      oversold,
      chartData,
      this.dateFilter
    );
  }

  private updatePriceOptions(payer: EPayerTypes): void {
    const reports = this.reports as IPriceReport[];

    const timestamps: number[] = [];
    const data: number[] = [];
    const rsiData: number[] = [];

    for (let report of reports) {
      const timestamp: number = getTimestamp(report.moment);
      const value: number = report.value;
      const rsi: number = report.rsi;

      timestamps.push(timestamp);
      data.push(value);
      rsiData.push(rsi);
    }

    const chartData: IChartData = {
      name: 'Фьючерс',
      timestamps: timestamps,
      data: data,
    };

    let payerField: 'yur' | 'fiz';
    switch (payer) {
      case EPayerTypes.INDIVIDUAL:
        payerField = 'fiz';
        break;
      case EPayerTypes.LEGAL:
        payerField = 'yur';
        break;
    }

    const overbought = this.currentAsset?.rsi?.[payerField]?.overbought || 70;
    const oversold = this.currentAsset?.rsi?.[payerField]?.oversold || 30;
    this.dateFilter.type = EDateFilterIntervalType.DAY;

    this.options = getPriceChartOptions(
      chartData,
      overbought,
      oversold,
      rsiData,
      this.dateFilter
    );
  }

  public updateTimer(state: ChartFilters): void {
    if (this.timer) {
      clearInterval(this.timer);
    }

    const interval = convertIntervalToDimension(state.interval);
    if (interval) {
      this.timer = setInterval(() => this.fetchReports(state), interval);
    }
  }

  public updateDateFilter(state: ChartFilters): void {
    this.dateFilter = convertIntervalAndPeriodToDateFilter(
      state.interval,
      state.period
    );
  }

  public fetchReports(state?: ChartFilters): void {
    if (!this.currentAsset?.available) return;

    this.isLoading = true;
    this.reports = [];

    const izFiz: boolean = state?.payer == EPayerTypes.INDIVIDUAL;

    let fetchPosition: Observable<any>;

    switch (state?.type) {
      case EChartTypes.INDIVIDUAL:
        fetchPosition = this._chartRepository.getIndividualPosition(
          this.currentAsset.id,
          this.dateFilter,
          izFiz
        );
        break;
      case EChartTypes.LEGAL:
        fetchPosition = this._chartRepository.getLegalPosition(
          this.currentAsset.id,
          this.dateFilter,
          izFiz
        );
        break;
      case EChartTypes.RSI:
        fetchPosition = this._chartRepository.getRsi(
          this.currentAsset.id,
          this.dateFilter,
          izFiz
        );
        break;
      case EChartTypes.FUTURES:
        fetchPosition = this._chartRepository.getPrice(
          this.currentAsset.id,
          this.dateFilter,
          izFiz
        );
        break;
      default:
        fetchPosition = this._chartRepository.getIndividualPosition(
          this.currentAsset.id,
          this.dateFilter,
          izFiz
        );
        break;
    }

    fetchPosition.subscribe((response) => {
      this.reports = response.data;
      this.isLoading = false;
      this.updateChart(state);
    });
  }
}
