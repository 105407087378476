<div class="select">
  <div
    class="select__current flex"
    [ngClass]="{ 'select__current--opened': isOptionsOpened }"
    (click)="setIsOptionsOpened(!isOptionsOpened)"
  >
    <span class="mr-3">Вид</span>
    <ng-container [ngSwitch]="current?.value">
      <ng-container *ngSwitchCase="ChartView.SINGLE">
        <icon-single-frame />
      </ng-container>
      <ng-container *ngSwitchCase="ChartView.DOUBLE_HOR">
        <icon-double-horizontal-frame />
      </ng-container>
      <ng-container *ngSwitchCase="ChartView.DOUBLE_VER">
        <icon-double-vertical-frame />
      </ng-container>
      <ng-container *ngSwitchCase="ChartView.TRIPLE">
        <icon-triple-frame />
      </ng-container>
      <ng-container *ngSwitchCase="ChartView.FOUR">
        <icon-four-frame />
      </ng-container>
    </ng-container>
  </div>
  <ul
    class="select__options p-2"
    [ngClass]="{
      'select__options--hidden': !isOptionsOpened
    }"
  >
    <li
      class="select__option"
      [ngClass]="{ 'select__option--current': option == current }"
      *ngFor="let option of options"
      (click)="setOption(option)"
    >
      <ng-container [ngSwitch]="option.value">
        <ng-container *ngSwitchCase="ChartView.SINGLE">
          <icon-single-frame />
        </ng-container>
        <ng-container *ngSwitchCase="ChartView.DOUBLE_HOR">
          <icon-double-horizontal-frame />
        </ng-container>
        <ng-container *ngSwitchCase="ChartView.DOUBLE_VER">
          <icon-double-vertical-frame />
        </ng-container>
        <ng-container *ngSwitchCase="ChartView.TRIPLE">
          <icon-triple-frame />
        </ng-container>
        <ng-container *ngSwitchCase="ChartView.FOUR">
          <icon-four-frame />
        </ng-container>
      </ng-container>
    </li>
  </ul>
</div>
