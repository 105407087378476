import { Component } from '@angular/core';
import { ChartService } from '../../chart.service';
import {
  IDateFilter,
  dateFilters,
  EDateFilterIntervalType,
} from '../../models/date-filter';

/*
  todo
  удалить в конце разработки
*/
@Component({
  selector: 'app-chart-period',
  templateUrl: './chart-period.component.html',
  styleUrls: ['./chart-period.component.scss'],
})
export class ChartPeriodComponent {
  public dateFilters: IDateFilter[] = dateFilters;

  public customFrom: Date | null = null;
  public customTo: Date | null = null;

  constructor(public readonly chartService: ChartService) {}

  changePeriodHandler(filter: IDateFilter): void {
    if (filter.code === this.chartService.dateFilter.code) return;
    this.chartService.setDateFilter(filter);
  }

  customClickHandler(): void {
    if (
      this.dateFilters.find(
        (filter) => filter.code === this.chartService.dateFilter.code
      )
    ) {
      this.updateCustomPeriod();
    }
  }

  updateCustomPeriod(): void {
    if (this.customFrom || this.customTo) {
      this.changePeriodHandler({
        code: Date.now().toString(),
        range: {
          from: this.customFrom || new Date(1900, 1, 1),
          to: this.customTo || new Date(),
        },
        interval: 1,
        type: EDateFilterIntervalType.DEFAULT,
      });
    } else {
      const currentFilter: IDateFilter =
        this.dateFilters.find(
          (filter) => filter.code === this.chartService.dateFilter.code
        ) || this.dateFilters[2];
      this.changePeriodHandler(currentFilter);
    }
  }

  setFromPeriod(from: string): void {
    if (from.length > 0) {
      const [day, month, year] = from.split('.');
      this.customFrom = new Date(Number(year), Number(month) - 1, Number(day));
    } else {
      this.customFrom = null;
    }
    this.updateCustomPeriod();
  }

  setToPeriod(to: string): void {
    if (to.length > 0) {
      const [day, month, year] = to.split('.');
      this.customTo = new Date(Number(year), Number(month) - 1, Number(day));
    } else {
      this.customTo = null;
    }
    this.updateCustomPeriod();
  }
}
