import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {AuthService, EAuthPopUpState} from "../../../../modules/auth/auth.service";
import {AuthRepository} from "../../../../modules/auth/repository/auth";
import {catchError, ObservableInput} from "rxjs";
import {NotifierService} from "angular-notifier";
import {IRegisterStartResponseDTO} from "../../../../modules/auth/dto/register-start.dto";
import {UserService} from "../../../../modules/user/user.service";
import {HttpResponse} from "@angular/common/http";

@Component({
  selector: 'app-register-start-form',
  templateUrl: './register-start-form.component.html',
})
export class RegisterStartFormComponent implements OnInit{
  public isLoading = false;
  public isSuccess = false;

  public form = new FormGroup({
    email: new FormControl<string>('', [
      Validators.required,
      Validators.email,
    ]),
  });

  constructor(
    public readonly authService: AuthService,
    private readonly _authRepository: AuthRepository,
    private readonly _notifierService: NotifierService,
    private readonly _userService: UserService
  ) {
  }

  ngOnInit() {
    Object.values(this.form.controls).map(item => {
      item.valueChanges.subscribe(() => {
        this.authService.isOneOfAuthFormIsInvalid = item.invalid && item.touched;
      });
    });
  }

  public get emailControl(): FormControl {
    return this.form.controls.email;
  }

  public get emailControlHasError() {
    return this.emailControl.errors && Object.keys(this.emailControl.errors).length
  }

  public async onSubmit() {
    if (this.isSuccess) {
      this.authService.setAuthPopUpState(EAuthPopUpState.CLOSED);
      return;
    }

    this.form.disable();
    this.isLoading = true;

    const success = () => {
      this.form.enable();
      this.emailControl.disable();
      this.isSuccess = true;
      this.isLoading = false;
    }

    this._authRepository.registerStart({
      email: this.form.get('email')?.value?.trim() as string,
    }).pipe(
        catchError<HttpResponse<IRegisterStartResponseDTO>, ObservableInput<HttpResponse<IRegisterStartResponseDTO>>>((selector) => {
          if (selector.status === 200) {
            success()
            return selector;
          }

          this.form.enable();
          this.isLoading = false;
          this._notifierService.notify("error", "Не удалось отправить письмо для продолжения регистрации...")

          return selector;
        })
    ).subscribe(async (httpResponse) => {
      const response = httpResponse.body;
      if (response?.success) {
        success()
      }
    });
  }
}
