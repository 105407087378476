/*
  todo
  удалить в конце разработки
*/
export enum EDateFilterIntervalType {
  DEFAULT = 'day',
  DAY = 'day',
  MINUTE = 'minute',
}

export interface IDateFilter {
  code: string;
  name?: string;
  range: {
    from: Date;
    to: Date;
  };
  interval: number;
  type: EDateFilterIntervalType;
  hideDate?: boolean;
}

export const dateFilters: IDateFilter[] = [
  {
    code: 'week',
    name: '1 неделя',
    range: {
      from: new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000),
      to: new Date(),
    },
    interval: 5,
    type: EDateFilterIntervalType.MINUTE,
  },
  {
    code: 'one-month',
    name: '1 месяц',
    range: {
      from: new Date(new Date().getTime() - 31 * 24 * 60 * 60 * 1000),
      to: new Date(),
    },
    interval: 1,
    type: EDateFilterIntervalType.DAY,
  },
  {
    code: 'six-months',
    name: '6 месяцев',
    range: {
      from: new Date(new Date().getTime() - 183 * 24 * 60 * 60 * 1000),
      to: new Date(),
    },
    interval: 1,
    type: EDateFilterIntervalType.DAY,
  },
  {
    code: 'one-year',
    name: '1 год',
    range: {
      from: new Date(new Date().getTime() - 365 * 24 * 60 * 60 * 1000),
      to: new Date(),
    },
    interval: 1,
    type: EDateFilterIntervalType.DAY,
  },
  {
    code: 'two-years',
    name: '2 года',
    range: {
      from: new Date(new Date().getTime() - 730 * 24 * 60 * 60 * 1000),
      to: new Date(),
    },
    interval: 1,
    type: EDateFilterIntervalType.DAY,
  },
  {
    code: 'all',
    name: 'За все время',
    range: {
      from: new Date(1900, 1, 1),
      to: new Date(2100, 1, 1),
    },
    interval: 1,
    type: EDateFilterIntervalType.DAY,
    hideDate: true,
  },
];
