import {
  getAnimationSettings,
  getAxisX,
  getGridSettings,
  getTooltipSettings,
  getWatermark,
} from '../chart-theme';
import { IChartData } from '../../models/chart';
import { EChartsOption } from 'echarts';
import { addNullsToData } from '../chart-utils';
import { IDateFilter } from '../../models/date-filter';

export function getSoloChartOptions(chartData: IChartData, dateFilter: IDateFilter): EChartsOption {
  const data = addNullsToData<number>(chartData.data);

  return {
    ...getAnimationSettings(),
    graphic: getWatermark(),
    color: chartData.color,
    textStyle: {
      fontSize: 14,
      fontFamily: 'Inter',
    },
    grid: getGridSettings(),
    title: {
      show: false,
    },
    tooltip: getTooltipSettings(dateFilter),
    xAxis: getAxisX(chartData.timestamps, dateFilter),
    yAxis: {
      type: 'value',
      boundaryGap: [0, '10%'],
      splitLine: {
        show: true,
        lineStyle: {
          color: '#CFCFD0',
          opacity: 0.25,
        },
      },
      axisLabel: {
        margin: 35,
        inside: true,
        color: 'white',
        verticalAlign: 'bottom',
        align: 'left',
      },
    },
    series: {
      name: chartData.name,
      type: 'line',
      showSymbol: false,
      areaStyle: {
        color: {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            { offset: 0, color: chartData.color ?? 'white' },
            { offset: 1, color: 'transparent' },
          ],
        },
      },
      symbolSize: 8,
      emphasis: {
        itemStyle: {
          borderColor: chartData.color,
          borderWidth: 13,
          opacity: 0.3,
          color: chartData.color,
        },
      },
      data: data,
    },
  };
}
