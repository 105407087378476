import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Signal,
  signal,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { trigger } from '@angular/animations';
import { fullscreenAnimation, skeletonAnimation } from './fullscreen-animation';
import { ActivatedRoute, Router } from '@angular/router';
import {
  EAssetTypes,
  assetTypesOptions,
} from 'src/app/modules/assets/models/asset-types';
import { AssetsService } from 'src/app/modules/assets/assets.service';
import {
  ChartView,
  EChartDateIntervals,
  EChartTimeIntervals,
  EChartTypes,
  chartDateIntervals,
  chartTimeIntervals,
  chartTypeOptions,
  chartViewOptions,
} from '../../models/chart-types';
import { EPayerTypes, payerTypeOptions } from '../../models/payer-types';
import { ChartService } from '../../chart.service';
import { Subscription, take } from 'rxjs';
import {
  EPositionTypes,
  individualPositionTypeOptions,
  legalPositionTypeOptions,
} from '../../models/position-types';
import { IOption } from 'src/app/shared/interfaces/chart-options.interface';
import { dateFilters, IDateFilter } from '../../models/date-filter';

@Component({
  selector: 'app-chart-panel',
  templateUrl: './chart-panel.component.html',
  styleUrls: ['./chart-panel.component.scss'],
  animations: [
    trigger('fullscreenAnimation', fullscreenAnimation),
    trigger('skeletonAnimation', skeletonAnimation),
  ],
})
export class ChartPanelComponent implements OnInit, OnDestroy {
  @ViewChild('analytics') chartPanel: ElementRef;
  @ViewChild('skeleton') skeletonPanel: ElementRef;

  @ViewChild('singleFrame') singleTemplate: TemplateRef<any>;
  @ViewChild('doubleHorFrame') doubleHorTemplate: TemplateRef<any>;
  @ViewChild('doubleVerFrame') doubleVerTemplate: TemplateRef<any>;
  @ViewChild('tripleFrame') tripleTemplate: TemplateRef<any>;

  public positionTypeOptions: IOption<EPositionTypes>[] = [];
  public chartTypeOptions: IOption<EChartTypes>[] = chartTypeOptions;
  public payerTypeOptions: IOption<EPayerTypes>[] = payerTypeOptions;
  public assetsTypeOptions: IOption<EAssetTypes>[] = assetTypesOptions;
  public timeIntervalsOptions: IOption<EChartTimeIntervals>[] =
    chartTimeIntervals;
  public dateIntervalsOptions: IOption<EChartDateIntervals>[] =
    chartDateIntervals;
  public viewOptions: IOption<ChartView>[] = chartViewOptions;

  public EChartTypes = EChartTypes;
  public ChartView = ChartView;

  private subscription: Subscription;

  public get currentViewOption(): ChartView {
    return this.chartService.currentChartFiltersState.view;
  }

  constructor(
    public readonly router: Router,
    public readonly activatedRoute: ActivatedRoute,
    public readonly assetsService: AssetsService,
    public readonly chartService: ChartService
  ) {}

  ngOnInit(): void {
    this.viewOptions.forEach((option) => {
      option.template =
        option.value === ChartView.SINGLE
          ? this.singleTemplate
          : option.value === ChartView.DOUBLE_HOR
          ? this.doubleHorTemplate
          : option.value === ChartView.DOUBLE_VER
          ? this.doubleVerTemplate
          : option.value === ChartView.TRIPLE
          ? this.tripleTemplate
          : this.singleTemplate;
    });

    this.positionTypeOptions =
      this.chartService.currentChartFiltersState.type == EChartTypes.INDIVIDUAL
        ? individualPositionTypeOptions
        : legalPositionTypeOptions;

    this.subscription = this.activatedRoute.queryParams
      .pipe(take(1))
      .subscribe((params) => {
        let isin = params['isin'];
        let currentAsset = this.chartService.currentAsset;

        if (isin && !currentAsset) {
          this.chartService.fetchAssetByIsin(isin);
        } else {
          this.assetsService.resetFilters(EAssetTypes.STOCK);
        }
      });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.chartService.currentAsset = null;
    this.chartService.options = {};
  }

  isShowPositions(): boolean {
    return [EChartTypes.INDIVIDUAL, EChartTypes.LEGAL].includes(
      this.chartService.currentChartFiltersState.type
    );
  }

  isShowPayer(): boolean {
    return [
      EChartTypes.INDIVIDUAL,
      EChartTypes.LEGAL,
      EChartTypes.RSI,
      EChartTypes.FUTURES,
    ].includes(this.chartService.currentChartFiltersState.type);
  }

  setChartView(view: ChartView): void {
    this.chartService.patchChartFiltersState('view', view);
  }

  setChartTypeHandler(type: EChartTypes) {
    if (type == EChartTypes.INDIVIDUAL) {
      this.positionTypeOptions = individualPositionTypeOptions;
    } else if (type == EChartTypes.LEGAL) {
      this.positionTypeOptions = legalPositionTypeOptions;
    }

    this.chartService.patchChartFiltersState('type', type);
  }

  toggleFullscreen(): void {
    setTimeout(() => {
      if (this.chartService.isFullscreen) {
        document.body.style.overflow = '';
        this.chartService.isFullscreen = false;
      } else {
        this.skeletonPanel.nativeElement.style.height = `${this.chartPanel.nativeElement.offsetHeight}px`;
        this.skeletonPanel.nativeElement.style.position = 'static';
        document.body.style.overflow = 'hidden';
        this.chartService.isFullscreen = true;
      }
    }, 0);
  }

  getCurrentOption(options: IOption<any>[], value: any): any {
    return options.find((option) => option.value == value) || options[0];
  }
}
