import { IOption } from 'src/app/shared/interfaces/chart-options.interface';

export enum ChartView {
  SINGLE,
  DOUBLE_HOR,
  DOUBLE_VER,
  TRIPLE,
  FOUR,
}

export enum EChartTypes {
  INDIVIDUAL = 'individual',
  LEGAL = 'legal',
  RSI = 'rsi',
  FUTURES = 'futures',
}

export enum EChartTimeIntervals {
  FIVEMINUTES,
  THIRTYMINUTES,
  HOUR,
}

export enum EChartDateIntervals {
  TODAY,
  WEEK,
  MONTH,
  SIXMONTHS,
}

export const chartTimeIntervalsAliases: Record<EChartTimeIntervals, string> = {
  [EChartTimeIntervals.FIVEMINUTES]: '5 мин.',
  [EChartTimeIntervals.THIRTYMINUTES]: '30 мин.',
  [EChartTimeIntervals.HOUR]: '1 час',
};

export const chartDateIntervalsAliases: Record<EChartDateIntervals, string> = {
  [EChartDateIntervals.TODAY]: 'Сегодня',
  [EChartDateIntervals.WEEK]: 'Неделя',
  [EChartDateIntervals.MONTH]: 'Месяц',
  [EChartDateIntervals.SIXMONTHS]: '6 месяцев',
};

export const ChartTypesAliases: Record<EChartTypes, string> = {
  [EChartTypes.INDIVIDUAL]: 'Покупатели-Продавцы',
  [EChartTypes.LEGAL]: 'Покупки-Продажи',
  [EChartTypes.RSI]: 'Сигналы',
  [EChartTypes.FUTURES]: 'Цена актива',
};

export const chartTypeOptions: IOption<EChartTypes>[] = [
  {
    label: ChartTypesAliases[EChartTypes.LEGAL],
    value: EChartTypes.LEGAL,
  },
  {
    label: ChartTypesAliases[EChartTypes.INDIVIDUAL],
    value: EChartTypes.INDIVIDUAL,
  },
  {
    label: ChartTypesAliases[EChartTypes.RSI],
    value: EChartTypes.RSI,
  },
  {
    label: ChartTypesAliases[EChartTypes.FUTURES],
    value: EChartTypes.FUTURES,
  },
];

export const chartTimeIntervals: IOption<EChartTimeIntervals>[] = [
  {
    label: chartTimeIntervalsAliases[EChartTimeIntervals.FIVEMINUTES],
    value: EChartTimeIntervals.FIVEMINUTES,
  },
  {
    label: chartTimeIntervalsAliases[EChartTimeIntervals.THIRTYMINUTES],
    value: EChartTimeIntervals.THIRTYMINUTES,
  },
  {
    label: chartTimeIntervalsAliases[EChartTimeIntervals.HOUR],
    value: EChartTimeIntervals.HOUR,
  },
];

export const chartDateIntervals: IOption<EChartDateIntervals>[] = [
  {
    label: chartDateIntervalsAliases[EChartDateIntervals.TODAY],
    value: EChartDateIntervals.TODAY,
  },
  {
    label: chartDateIntervalsAliases[EChartDateIntervals.WEEK],
    value: EChartDateIntervals.WEEK,
  },
  {
    label: chartDateIntervalsAliases[EChartDateIntervals.MONTH],
    value: EChartDateIntervals.MONTH,
  },
  {
    label: chartDateIntervalsAliases[EChartDateIntervals.SIXMONTHS],
    value: EChartDateIntervals.SIXMONTHS,
  },
];

export const chartViewOptions: IOption<ChartView>[] = [
  {
    label: '',
    value: ChartView.SINGLE,
  },
  {
    label: '',
    value: ChartView.DOUBLE_HOR,
  },
  {
    label: '',
    value: ChartView.DOUBLE_VER,
  },
  {
    label: '',
    value: ChartView.TRIPLE,
  },
  {
    label: '',
    value: ChartView.FOUR,
  },
];
